export const firebaseConfig = {
	apiKey: 'AIzaSyChfC5VWCaa-58Jq45Rw5RWf-dhCKh4hQ8',
	authDomain: 'routingappapi-testing.firebaseapp.com',
	databaseURL: 'https://routingappapi-testing.firebaseio.com',
	projectId: 'routingappapi-testing',
	storageBucket: 'routingappapi-testing.appspot.com',
	messagingSenderId: '803124367577',
};

export const environment = {
	envName: 'testing',
	production: false,
	debug: false,
	routingAppApiUrl: 'https://routingappapi-testing.appspot.com',
	routingApiURL: 'https://api-testing.flutaro.de',
	POSEIDON_URL: 'https://flutaro-poseidon-test.ey.r.appspot.com',
	ATHENE_URL: 'https://athene-test.ey.r.appspot.com',
	firebaseConfig: firebaseConfig,
};
