import { Pipe, PipeTransform } from '@angular/core';
import { Vehicle } from '@flutaro/package/lib/model/Vehicle';
import { AppScheduleBasics, SCHEDULE_TYPES } from '@flutaro/package/lib/model/AppScheduleClasses';
import {
	getClosestScheduleAfterDate,
	getClosestScheduleBeforeDate,
	getClosestScheduleForEntityAfterDate,
	getClosestScheduleForEntityBeforeDate,
	getSchedulesEntityName,
} from '@flutaro/package/lib/functions/AppScheduleFunctions';
import { getEarliestDateOfDates, getLatestDateOfDates } from '@flutaro/package/lib/functions/DataDateFunctions';
import { Driver } from '@flutaro/package/lib/model/Driver';

@Pipe({
	name: 'scheduleMaxDate',
	pure: false,
})
export class ScheduleMaxDatePipe implements PipeTransform {
	transform(vehicle: Vehicle, schedule: AppScheduleBasics, vehicles: Vehicle[], isStart: boolean): Date {
		const schedules =
			schedule.type === SCHEDULE_TYPES.DRIVER_SCHEDULE ? vehicle.driverSchedules : vehicle.trailerSchedules;
		const vehiclesFollowingSchedule = getClosestScheduleAfterDate(schedules, schedule.startDate, schedule.id);
		const entitiesScheduleAfterScheduleStart = getClosestScheduleForEntityAfterDate(
			schedule.entityId,
			vehicles,
			schedule.startDate,
			schedule.type,
			schedule.id,
		);
		return getEarliestDateOfDates([
			vehiclesFollowingSchedule?.startDate,
			entitiesScheduleAfterScheduleStart?.startDate,
			isStart ? schedule.endDate : null,
		]);
	}
}

@Pipe({
	name: 'scheduleMinDate',
	pure: false,
})
export class ScheduleMinDatePipe implements PipeTransform {
	transform(vehicle: Vehicle, schedule: AppScheduleBasics, vehicles: Vehicle[], isStart: boolean): Date {
		const schedules =
			schedule.type === SCHEDULE_TYPES.DRIVER_SCHEDULE ? vehicle.driverSchedules : vehicle.trailerSchedules;
		const previousSchedule = getClosestScheduleBeforeDate(schedules, schedule.startDate, schedule.id);
		const entitiesScheduleBeforeScheduleStart = getClosestScheduleForEntityBeforeDate(
			schedule.entityId,
			vehicles,
			schedule.startDate,
			schedule.type,
			schedule.id,
		);
		return getLatestDateOfDates([
			previousSchedule?.endDate,
			entitiesScheduleBeforeScheduleStart?.endDate,
			isStart ? null : schedule.startDate,
		]);
	}
}

@Pipe({
	name: 'entitiesFollowingSchedule',
	pure: false,
})
export class EntitiesFollowingScheduleAfterDatePipe implements PipeTransform {
	transform(schedule: AppScheduleBasics, vehicles: Vehicle[]): AppScheduleBasics {
		return getClosestScheduleForEntityAfterDate(
			schedule.entityId,
			vehicles,
			schedule.startDate,
			schedule.type,
			schedule.id,
		);
	}
}

@Pipe({
	name: 'vehicleForScheduleId',
	pure: false,
})
export class VehicleForScheduleIdPipe implements PipeTransform {
	transform(scheduleId: string, vehicles: Vehicle[]): Vehicle {
		return vehicles.find((vehicle) => vehicle.driverSchedules?.find((schedule) => schedule.id === scheduleId));
	}
}

@Pipe({
	name: 'scheduleEntityName',
})
export class GetSchedulesEntityNamePipe implements PipeTransform {
	transform(entityId: string, type: SCHEDULE_TYPES, vehicles: Vehicle[], drivers: Driver[] | null): string {
		return getSchedulesEntityName(type, entityId, vehicles, drivers);
	}
}
